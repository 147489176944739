import request from '@/utils/request'

export default {
  getArticleList(id) {
    return request({
      url: `/bio/jfk-info/getArticleById/`+id,
      method: 'get'
    })
  },

  getArticleListBylist(page,limit) {
    return request({
      url: `/bio/jfk-info/getArticleListBylist/${page}/${limit}`,
      method: 'get'
    })
  }
}
